<template>
  <ul class="flex flex-col">
    <li
      v-if="data.length"
      class="text-black text-xl font-semibold p-2 mt-2"
    >
      Aufgaben
    </li>
    <li
      v-for="(row, index) in $v.data.$each.$iter"
      :key="`task-${index}`"
      class="flex flex-col w-full mb-2 rounded border card"
    >
      <a
        href=""
        @click.prevent="
          $emit(
            'current',
            current === `${$options.name}-${index}`
              ? null
              : `${$options.name}-${index}`
          )
        "
        class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold flex justify-between items-center card-header"
        ><span>{{ data[index].task.name }}</span>

        <span
          v-if="data[index].task && data[index].mark"
          class="border font-semibold rounded px-1.5 py-0.5 flex"
          :class="
            isAssesment ? 'bg-blue-600 text-white ' : 'bg-green-400 text-white '
          "
        >
          {{ marks(data[index].mark) }}</span
        >
        <span
          v-else
          class="border border-dotted rounded border-gray-400 p-2.5"
          :class="{ 'border-red-600': posted }"
        ></span>
      </a>
      <div
        v-if="printing || current === `${$options.name}-${index}`"
        class="p-2 flex flex-col"
      >
        <p class="py-2 font-semibold">{{ data[index].task.description }}</p>
        <rating-scales
          v-model="row.mark.$model"
          :scales="scales"
          :is-assesment="isAssesment"
        />

        <vue-editor
          v-model="data[index].comment"
          :editorToolbar="[
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: 'left' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
          ]"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'taskevaluationexternal',
  props: ['rows', 'current', 'scales', 'isAssesment', 'who', 'marks', 'posted'],
  data() {
    return {
      data: [...this.rows],
    }
  },
  validations: {
    data: {
      $each: {
        mark: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    ratings(index) {
      return this.data[index]
    },
  },
  watch: {
    data: {
      handler: function(n) {
        this.$v.$touch()
        this.$emit('error', {
          key: 'taskevaluationexternal',
          value: this.$v.$invalid,
          error: 'Bitte bewerten Sie die Hauptaufgaben',
        })
        this.$emit('update', { key: 'taskevaluationexternal', value: n })
      },
      deep: true,
    },
  },
}
</script>

<style>
.card-header {
  background: none;
  background-color: #e9e9e9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.card {
  box-shadow: 0 1px 1px 1px rgb(33 36 55 / 3%);
  border-radius: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 5px solid #f0f0f0;
}
</style>
