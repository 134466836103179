<template>
  <div class="w-2/3 m-auto pt-8 min-h-screen">
    <p
      v-if="message"
      class="rounded bg-gray-300 px-4 py-2"
      :class="success ? 'text-green-500' : 'text-red-500'"
      v-html="message"
    ></p>
    <div v-else class="w-full border rounded shadow-sm bg-white p-2">
      <div class="w-full flex flex-col">
        <h1 class="text-2xl font-bold py-4">{{ externalTitle }}</h1>

        <ul class="flex flex-col-reverse">
          <li v-for="(row, component, index) in performanceReview" :key="`row-${index}`">
            <component
              :is="component"
              :rows="row"
              :scales="scales"
              :current="current"
              :marks="marks"
              :is-assesment="false"
              :posted="posted"
              @current="payload => (current = payload)"
              @update="update"
              @error="error"
            />
          </li>
        </ul>
      </div>
      <button @click.prevent="save" class="bg-blue-600 rounded px-2 py-1 text-white">
        Feedback senden
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { xhr } from '@/utils/axios'
import goalevaluationexternal from '@/components/Forms/Employee/PerformanceReview/Review/GoalExternal'
import taskevaluationexternal from '@/components/Forms/Employee/PerformanceReview/Review/TaskExternal'
import personalityskillevaluationexternal from '@/components/Forms/Employee/PerformanceReview/Review/PersonalitySkillExternal'

export default {
  name: 'external-feedback',
  components: {
    goalevaluationexternal,
    personalityskillevaluationexternal,
    taskevaluationexternal,
  },
  data() {
    return {
      token: this.$route.params.token || this.$route.query.token,
      client: this.$route.params.client || this.$route.query.client,
      performanceReview: {},
      errors: {},
      scales: [],
      current: null,
      message: null,
      success: false,
      externalTitle: '',
      posted: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      xhr
        .get(`/performance-evaluation/extern?token=${this.token}&client=${this.client}`)
        .then(response => {
          if (response.data.message) {
            this.message = response.data.message
          } else {
            this.scales = response.data.data.rating_scales
            this.externalTitle = response.data.data.title
              ? response.data.data.title
              : response.message
            this.performanceReview = {
              ..._.omit(response.data.data.data, ['id']),
            }
          }
        })
    },
    save() {
      this.posted = true
      if (!_.some(_.values(this.errors), Boolean)) {
        xhr
          .patch(
            `/performance-evaluation/feedback/${this.token}/${this.client}/`,
            { ...this.performanceReview },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(response => {
            this.message = response.data.message
          })
          .catch(err => {
            this.success = err.response.data.success
            this.message = err.response.data.message
          })
      } else {
        this.$toast.error('Alle Markierungen sind obligatorisch')
      }
    },
    update(payload) {
      this.performanceReview[payload.key] = [...payload.value]
    },
    error(payload) {
      this.errors[payload.key] = payload.value
    },
    marks(id) {
      return id ? _.first(_.filter(this.scales, scale => scale.id === id)).name : null
    },
  },
}
</script>

<style></style>
